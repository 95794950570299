import modal from './modal/modal.vue'
import modalLoad from './modal/modalLoad.vue'
import modalList from './modal/modalList.vue'
import tabs from './tabs/tabs.vue'
import tabNav from './tabs/tabNav.vue'
import tabPane from './tabs/tabPane.vue'
import forEach from './forEach.vue'
import numberKeyBoard from './numberKeyBoard/numberKeyBoard.vue'
import inputPattern from './inputPattern/inputPattern.vue'
import analogKeyBoard from './analogKeyBoard/analogKeyBoard.vue'
import layoutRc from './layoutRc/layoutRc.vue'
import pageTurning from './pageTurning/pageTurning.vue'
import selectTree from './selectTree/selectTree.vue'
import categorysPage from './categorysPage/categorysPage.vue'
import keyBoardInput from './keyBoardInput/keyBoardInput.vue'
import tablePageBtn from './tablePageBtn/tablePageBtn.vue'
import tagSoldOut from './tag/tagSoldOut.vue'
import weighing from './weighing/weighing.vue'
import elTableVir from './elTableVir/elTableVir.vue'
import countDown from './countDown/countDown.vue'
import tableVir from './tableVir'
import datePicker from './datePicker/datePicker.vue'
import qrCode from './qrCode/qrCode.vue'
import editBtns from './editBtns/editBtns.vue'
import lunarCalendar from './lunarCalendar/lunarCalendar.vue'
import keyBoardHang from './keyBoard/keyBoardHang.vue'
import {requestWaitModal,requestWait} from './requestWaitModal/index.js'
import numberModal from './numberModal/numberModal.vue'
import orderStatusModal from './orderStatusModal/orderStatusModal.vue'
import fontSizePopBnt from './fontSizePopBnt/fontSizePopBnt.vue'
import flickerAni from './flickerAni/flickerAni.vue'
import floatingMenu from './floatingMenu/floatingMenu.vue'

const components=[
    modal,
    modalLoad,
    modalList,
    tabs,
    tabNav,
    tabPane,
    forEach,
    numberKeyBoard,
    inputPattern,
    analogKeyBoard,
    layoutRc,
    pageTurning,
    selectTree,
    categorysPage,
    keyBoardInput,
    tablePageBtn,
    tagSoldOut,
    weighing,
    elTableVir,
    countDown,
    datePicker,
    qrCode,
    editBtns,
    lunarCalendar,
    keyBoardHang,
    requestWaitModal,
    numberModal,
    orderStatusModal,
    fontSizePopBnt,
    flickerAni,
    floatingMenu
];

export default {
    install(app){
        components.forEach(component => {
            app.component(component.name, component);
        });

        tableVir.install(app);
        app.config.globalProperties.$requestWait=requestWait;
    }
}