<template>
<div class="orderMenuBox" :style="parent.checOutShow">
    <div class="stretch-box" :class="{open:isChecOutOpen}">
        <div class="left-la-box verticalCenter" @click="isChecOutOpen=false;"><i class="iconfont icon-fangxiangquan-left"></i></div>
        <div class="stretch-scroll-box">
            <div class="panel-heading">
                <div class="tr left-content">
                    <div class="td name">名称</div>
                    <div class="td tdnum">数量</div>
                    <div class="td tds">单价</div>
                    <div class="td subtotal">小计</div>
                </div>
            </div>
            <div class="scrollnot" :class="parent.isCheckOutclass"  v-mouse-scroll ref="box" v-scroll-anime="{up:'order-up',down:'order-down',liClass:'.slide-wrapper',paddingH:'25'}" v-if="orderMenuFilter">
                <div class="slide-wrapper" :class="oneLevelClass(item)" v-for="(item,index) in orderMenuFilter"  :key="item">
                    <div class="index">{{index+1}}</div>
                    <div class="animate-slide-start">
                        <div class="slide-content flex-box" @click="radioClick(item)" :title="item.EAT_XFName" >
                            <div class="left-content" style="position: relative">
                                <div class="tr">
                                    <div class="td name nowrap"><i class="iconfont icon-tesecai" v-show="parent.sysSetting.RunOrderListShowSpecialFoodFlag && item.Is_Special"></i>{{item.EAT_XFName}}</div>
                                    <div class="td tdnum">{{item.Eat_Number}}</div>
                                    <div class="td tds">
                                        <template v-if="item.isPromotion2">¥{{Conversion(item.zkPromotionPrice)}}</template>
                                        <template v-else>¥{{Conversion(item.Eat_CheckPrice)}}</template>
                                        /{{(item.Eat_XFSize||"").substring(0,1)}}
                                    </div>
                                    <div class="td subtotal nowrap">
                                        <template v-if="item.isPromotion2">{{Conversion((item.zkPromotionPrice * item.Eat_Number))}}</template>
                                        <template v-else>{{Conversion((item.Eat_CheckPrice * item.Eat_Number))}}</template>
                                    </div>
                                </div>
                                <div class="describe flex-box">
                                    <div class="inlineBlock nowrap2 flavor">
                                        <span v-if="item.Eat_MasName && item.Eat_MasName!='/'" :title="item.Eat_MasName" @mousedown="(e)=>{e.stopPropagation()}" @click="radioClick(item);masClick()">
                                            <i class="iconfont icon-a-beizhu2" ></i>
                                            口味:<span>{{item.Eat_MasName}}</span>
                                        </span>
                                    </div>
                                    <div class="inlineBlock tag-box">
                                        <span class="tag pin" v-if="item.Food_Type==1 && item.Eat_TcAutoID_Batch && !item.Eat_TcName && item.Eat_TcIndex==1">拼</span>
                                        <!--套-->
                                        <span class="tag tao" v-else :style="{visibility:(item.FoodProperty_Dimension?'':'hidden')}" >{{item.FoodProperty_Dimension||'套'}}</span>
                                       
                                        <!--赠,折-->
                                        <span class="tag give" v-if="item.Is_Gift">{{'赠'||'无'}}</span>
                                        <span class="tag give" v-if="item.is_discount" >{{'折'||'无'}}</span>
                                        <span class="tag retreat" v-if="item.isPromotion2">第二份折扣</span>
                                        <!--厨房状态-->
                                        <span class="tag delimit" v-if="item.KitSpeed_Dimension" >{{item.KitSpeed_Dimension}}</span>
                                        <!--退菜-->
                                        <span class="tag retreat" v-if="item.Refund_Number>0">退{{item.Refund_Number}}</span>
                                    </div>
                                    <div class="more" v-if="item.FoodSetmeals?.length>0 || item.OrderComboConsumes?.length>0">
                                        <div class="more-bnt" v-if="moreConsumeID!=item.Eat_ConsumeID" @click="moreConsumeID=item.Eat_ConsumeID">
                                            <i class="iconfont icon-xiala"></i>
                                        </div>
                                        <div class="more-bnt" v-else @click="moreConsumeID=null">
                                            <i class="iconfont icon-shangla"></i>
                                        </div>
                                    </div>
                                    <div class="time-box nowrap" :style="{visibility:(item.Time_Notice?'':'hidden')}">
                                        <i class="time-icon"></i>
                                        <span>{{item.Time_Notice}}</span>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div class="border"></div>
                    <!--套餐滑出-->
                    <!--下单前-->
                    <el-collapse-transition>
                    <template v-if="item.FoodSetmeals?.length>0">
                        <div class="more-box" v-show="moreConsumeID==item.Eat_ConsumeID">
                            <div class="slide-content" :class="{selected:food.FoodSetmeal_ID == parent.disRadioOrder?.FoodSetmeal_ID}"  :title="item.EAT_XFName" 
                                v-for="(food,foodindex) in item.FoodSetmeals" :key="foodindex" 
                                @click="parent.EatTcClick(food,foodindex,item)" >
                                <div class="flex-box">
                                    <div class="left-content" style="position: relative">
                                        <div class="tr">
                                            <div class="td name nowrap">{{food.ReplaceFood_Name||food.FoodSetmeal_Name}}</div>
                                            <div class="td tdnum">{{food.FoodSetmeal_Number}}</div>
                                            <div class="td tds" >¥{{Conversion(food.FoodSetmeal_Price)}}/{{(food?.FoodSetmeal_Size||"份").substring(0,1)}}</div>
                                            <div class="td subtotal nowrap" >{{Conversion((food.FoodSetmeal_Price) * food.FoodSetmeal_Number) }}</div>
                                        </div>
                                        <div class="describe flex-box">
                                            <div class="inlineBlock flavor nowrap2" >
                                                <span v-if="food.Eat_MasName && food.Eat_MasName!='/'" :title="food.Eat_MasName" @mousedown="(e)=>{e.stopPropagation()}" 
                                                    @click="parent.EatTcClick(food,foodindex,item);masClick()">
                                                    <i class="iconfont icon-a-beizhu2" ></i>
                                                    口味:<span>{{food.Eat_MasName}}</span>
                                                </span>
                                            </div>
                                            <div class="inlineBlock tag-box">
                                                <span class="tag tao" style="visibility:hidden" >无</span>
                                                <!--套-->
                                                <!-- <span class="tag tao" v-if="item.FoodProperty_Dimension" >{{item.FoodProperty_Dimension}}</span> -->
                                                <!--赠,折-->
                                                <span class="tag give"  :style="{visibility:(food.FoodDiscount_Dimension?'':'hidden')}">{{food.FoodDiscount_Dimension||"无"}}</span>
                                                <!--厨房状态-->
                                                <span class="tag delimit" v-if="food.KitSpeed_Dimension">{{food.KitSpeed_Dimension}}</span>
                                            </div>
                                            <div class="more">&nbsp;&nbsp;</div>
                                            <div class="time-box nowrap" style="visibility:hidden">
                                                &nbsp;&nbsp;
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="item.OrderComboConsumes?.length>0">
                        <div class="more-box" v-show="moreConsumeID==item.Eat_ConsumeID">
                            <div class="slide-content" :class="{selected:parent.disRadioOrder.Is_Combo && food.Eat_ConsumeID == parent.disRadioOrder?.Eat_ConsumeID}"  
                                :title="food.EAT_XFName" 
                                v-for="(food,foodindex) in item.OrderComboConsumes" :key="foodindex" 
                                @click="radioClick(food)" >
                                <div class="flex-box">
                                    <div class="left-content" style="position: relative">
                                        <div class="tr">
                                            <div class="td name nowrap">{{food.EAT_XFName}}</div>
                                            <div class="td tdnum">{{food.Eat_Number}}</div>
                                            <div class="td tds" >
                                                <template v-if="food.isPromotion2">¥{{Conversion(food.zkPromotionPrice)}}</template>
                                                <template v-else>¥{{Conversion(food.Eat_CheckPrice)}}</template>
                                                /{{(food?.Eat_XFSize||"份").substring(0,1)}}
                                            </div>
                                            <div class="td subtotal nowrap" >
                                                <template v-if="food.isPromotion2">{{Conversion((food.zkPromotionPrice * food.Eat_Number))}}</template>
                                                <template v-else>{{Conversion((food.Eat_CheckPrice * food.Eat_Number))}}</template>
                                            </div>
                                        </div>
                                        <div class="describe flex-box">
                                            <div class="inlineBlock flavor nowrap2" >
                                                <span v-if="food.Eat_MasName && food.Eat_MasName!='/'" :title="food.Eat_MasName" @mousedown="(e)=>{e.stopPropagation()}" 
                                                    @click="radioClick(food);masClick()">
                                                    <i class="iconfont icon-a-beizhu2" ></i>
                                                    口味:<span>{{food.Eat_MasName}}</span>
                                                </span>
                                            </div>
                                            <div class="inlineBlock tag-box">
                                                <span class="tag tao" style="visibility:hidden" >无</span>
                                                <!--套-->
                                                <!-- <span class="tag tao" v-if="item.FoodProperty_Dimension" >{{item.FoodProperty_Dimension}}</span> -->
                                                <!--赠,折-->
                                                <span class="tag give" v-if="food.Is_Gift" >{{'赠'||'无'}}</span>
                                                <span class="tag give" v-if="food.is_discount" >{{'折'||'无'}}</span>
                                                <span class="tag retreat" v-if="food.isPromotion2">第二份折扣</span>
                                                <!--厨房状态-->
                                                <span class="tag delimit" v-if="food.KitSpeed_Dimension">{{food.KitSpeed_Dimension}}</span>
                                                <!--退菜-->
                                                <span class="tag retreat" v-if="food.Refund_Number>0">退{{food.Refund_Number}}</span>
                                            </div>
                                            <div class="more">&nbsp;&nbsp;</div>
                                            <div class="time-box nowrap" style="visibility:hidden">
                                                &nbsp;&nbsp;
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    
                    </el-collapse-transition>
                </div>
            </div>
        </div>
    </div>
    <div v-show="false" ref="order-up"></div>
    <div v-show="false" ref="order-down"></div>
    <weighing v-model="parent.wgNum"></weighing>
    <!--修改 异常订单  -->
    <div class="abnormal-order" v-if="parent?.EatAutoID && parent?.isAbnormal">
        <i class="iconfont icon-jinggao"></i>
        <span class="flex1 nowrap2">【{{parent?.Rpt_Date}}】日历史异常单据!</span>
        <div class="bnt" @click="clickDoNot()">不做处理</div>
    </div>
    <div class="meber-info" v-if="parent?.meberInfo?.Card_No"><i class="iconfont icon-huiyuantouxiang"></i>会员卡号：{{parent?.meberInfo?.Card_No}}</div>
</div>
</template>

<script>
import { animation,deepClone,newGuid } from '/src/common' 
//订单展示
export default {
    name:"orderMenuBox",
    props:{
    },
    computed:{
        //父级
        parent(){
            return this.$parent;
        },
        //订单过滤后的显示数据
        orderMenuFilter(){
            //组合套餐
            let comboList={};

            let list=[];
            this.parent.orderMenuList?.forEach((it,index)=>{
                it._orderIndex=index;//标记排序号
                if(it.Food_Type==1 && it.Eat_TcAutoID_Batch && !it.Eat_TcName && it.Eat_TcIndex>1){
                    it.Is_Combo=true;
                    if(comboList[it.Eat_TcAutoID_Batch]){
                        comboList[it.Eat_TcAutoID_Batch].push(it);
                    }else{
                        comboList[it.Eat_TcAutoID_Batch]=[it];
                    }
                }else{
                    list.push(it);
                }
            })
            list.forEach(it=>{
                if(comboList[it.Eat_TcAutoID_Batch]){
                    it.OrderComboConsumes=comboList[it.Eat_TcAutoID_Batch];
                }
            })
            return list;
        },
        //满减活动方案
        fullPomotionList(){
            return this.promotionList?.filter(it=>it.ScenList?.some(item=>item.Name=="POS堂食") && it.PromotionType==1)||[];
        },
        //第二份折扣 活动
        discountList(){
            return this.promotionList?.filter(it=>it.ScenList?.some(item=>item.Name=="POS堂食") && it.PromotionType==2)||[];
        },
        /**计算合计 */
        orderTotal(){
            let countNumber=0;
            let totalMoney=0;
            let sumMoney=0;//总金额
            this.parent.orderMenuList?.forEach(order=>{
                totalMoney += Number(Number(order.Eat_CheckPrice * Number(order.Eat_Number)))
                sumMoney+=Number(order.Eat_SalePrice||0) * Number(order.Eat_Number||0);
                this.countNumber += Number(order.Eat_Number);
                if(order?.FoodSetmeals){
                    order.FoodSetmeals.forEach(food=>{
                        totalMoney += Number(Number(Number(food.FoodSetmeal_Price) * Number(food.FoodSetmeal_Number)));
                        sumMoney+=Number(Number(Number(food.FoodSetmeal_Price) * Number(food.FoodSetmeal_Number)));
                        this.countNumber += Number(food.FoodSetmeal_Number);
                    })
                }
            })
            return {
                countNumber:countNumber,
                totalMoney:Number(totalMoney.toFixed(2)),
                sumMoney:Number(sumMoney.toFixed(2))
            };
        },
        //满减 金额
        fullReductionMoney(){
            if(this.parent.orderMenuList?.length>0){
                return this.fullReduction();
            }
            return 0
        }
    },
    watch:{
        //订单份数 金额 发生变化 
        orderTotal(){
            //计算 第二份折扣活动
            this.promotions();
        }
    },
    data(){
        return {
            /*点菜单 列表是否展开 */
            isChecOutOpen:false,
            //记录 更多 打开的id
            moreConsumeID:null,
            //活动方案
            promotionList:null
        }
    },
    mounted(){
        console.log(this.$route.params)
        /**活动方案 */
        this.$cacheData.OnlinePromotions().then((d)=>{
            this.promotionList=d;
            if(d){//有方案时 重新计算金额
                this.promotions();
            }
            
        }).catch(e=>{
            console.log("活动方案数据获取失败：",e);
            this.$alert("活动方案数据获取失败："+e.message, "提示", {confirmButtonText: "确定"});
        })
    },
    methods:{
        //一级分类 类名
        oneLevelClass(item){
            let className=[];
            if(item.Food_Status>=0){
                className.push("selected");
            }

            if(this.parent.disRadioclass>=0){
                let trItem=this.parent.orderMenuList[this.parent.disRadioclass];
                if(this.parent.disRadioclass==item._orderIndex || (trItem && trItem.Is_Combo && item.OrderComboConsumes?.some(it=>it.Eat_ConsumeID==trItem.Eat_ConsumeID))){
                    className.push("selectedRow");
                }
            }
            
            return className;
        },
        //菜单选择菜品
        radioClick(item){
            this.$parent.radioClick(item._orderIndex,item);
        },
        /**更新标记 */
        updateRadio(){
            let disRadioOrder=this.parent.disRadioOrder;//当前选中的明细
            if(disRadioOrder){
                this.parent.orderMenuList.some((it,index)=>{
                    if(it.Eat_ConsumeID==disRadioOrder.Eat_ConsumeID){
                        this.$parent.radioClick(index,it);//点击菜品
                    }else if(it.FoodSetmeals){
                        return it.FoodSetmeals.some((fd,foodindex)=>{
                            if(fd.Eat_ConsumeID==disRadioOrder.Eat_ConsumeID){
                                this.parent.EatTcClick(fd,foodindex,it);//点击套餐明细菜品
                            }
                        })
                    }
                    return false;
                })
            }
        },
        //点击口味
        masClick(){
            this.$parent.MiddleMenuClick(this.$parent.QKOrderMain?.find(d=>d.ProjectItem_Key=='Mas'))
        },
        //没有小数点显示整数,有小数位 显示两位小数
        Conversion(num){
            if(parseFloat(num)===parseInt(num)){
                return parseInt(num);
            }else{
                return Number(num).toFixed(2);
            }
        },
        pageUp(){
            this.$refs['order-up'].dispatchEvent(new Event("click"));
        },
        pageDown(){
            this.$refs['order-down'].dispatchEvent(new Event("click"));
        },
        /**滚动到 选中位置 */
        scrollRow(){
            animation.scrollTopAnchor(this.$refs.box,".slide-wrapper.selectedRow")
        },
        /**计算促销活动 */
        promotions(){
            let list=(this.parent.orderMenuList||[])
            if(list.length>0){
                list.forEach(it=>{
                    //是否第二份 折扣
                    it.isPromotion2=false;
                    it.zkPromotionPrice=0;
                    it._promotionNumber=0;//记录 原活动菜品 已经使用了几次 折扣
                });
                let toDay=new Date();//当前时间
                let time=toDay.Format("hh:mm");//时分
                let weekDay=toDay.getDay();//周
                if(weekDay==0){
                    weekDay=7;
                }
                let promotion=this.discountList?.find(it=>it.BeginTime<=time && it.EndTime>=time && it.WeekDay?.indexOf(weekDay)>=0);
                if(promotion){//第二份 折扣 活动
                    let zkPromotion=promotion.SecondZkPromotion;//活动方案
                    //满足活动方案的菜
                    let zkItemList=this.getZKPromotionItem(zkPromotion);
                    if(zkItemList.length>0){
                        if(zkPromotion.PromKindType==1 && zkPromotion.SecondZkPromotionConsumes.length>0){//按活动菜品范围打折 要求是同一种菜品
                            //按菜品id 分组
                            zkItemList.groupBy("EAT_XFBMID",(group)=>{
                                let num=0;
                                let itemList=[];//
                                group.forEach(it=>{
                                    let number=Math.floor(it.Eat_Number); 
                                    for(let i=0;i<number;i++){
                                        num++;
                                        if(num>1){
                                            if(zkPromotion.SecondZkPromotionConsumes.length>num-2){//活动第几个菜 可折
                                                itemList.push({index:num,item:it});
                                            }else{
                                                num=1;
                                            }
                                        }
                                    }
                                })
                                //倒序排序 插入数据时会按 第几个 折扣 在原数据后排列
                                itemList.reverse().forEach(item=>{
                                    this.addZKItem(item.item,zkPromotion.SecondZkPromotionConsumes[item.index-2]);
                                })
                            })
                        }else if(zkPromotion.PromKindType==2 || zkPromotion.PromKindType==3){//按指定菜品范围打折【优先价低者折扣】 只有在指定范围的菜能折扣
                            if(zkItemList.length>0){
                                let specifyFoods=[],zkSpecifyFoods=[];
                                zkPromotion.SpecifyFoods?.forEach(food=>{
                                    if(zkItemList.some(it=>it.EAT_XFBMID==food.Item_ID)){//拥有第二份折扣活动的菜 在 指定折扣菜中
                                        zkSpecifyFoods.push(food);
                                    }else{//排除拥有第二份折扣活动的菜
                                        specifyFoods.push(food);
                                    }
                                })
                                if(zkPromotion.PromKindType==2){//按指定菜品范围打折【优先价低者折扣】 
                                    //活动菜品 销售价倒序 当活动菜与指定菜相同时 即 价格最高的 被当成 活动菜，价格最低当成 指定折扣菜
                                    zkItemList.sort((d1,d2)=>d2.Eat_SalePrice-d1.Eat_SalePrice);
                                }else{//按指定菜品范围打折【优先价高者折扣】 
                                    //活动菜品 销售价顺序 当活动菜与指定菜相同时 即 价格最高的 被当成 指定折扣菜，价格最低当成 活动菜
                                    zkItemList.sort((d1,d2)=>d1.Eat_SalePrice-d2.Eat_SalePrice);//销售价 顺序
                                }
                                zkItemList.forEach(it=>{
                                    let number=it.Eat_Number-it._promotionNumber;
                                    //排除已经折扣 或 活动折扣剩余数量小于1
                                    if(!it.isPromotion2 && number>=1){
                                        for(let i=0;i<number;i++){
                                            if(it.Eat_Number-it._promotionNumber<1){
                                                break;
                                            }
                                            let itemList=this.getSpecifyItem(zkPromotion,specifyFoods,zkSpecifyFoods);
                                            if(itemList.length>0){
                                                // itemList.some(item=>{//多个 可折扣的 找到一个折扣即可
                                                    
                                                // })
                                                let item=itemList[0];
                                                if(item.Eat_ConsumeID==it.Eat_ConsumeID){//如果是自身
                                                    if(it.Eat_Number-it._promotionNumber>=2){//剩余可活动的菜 必须 大于等于2 满足第二份折扣
                                                        it._promotionNumber++;//记录 原活动菜品 已经使用了几次 折扣
                                                        this.addZKItem(item,zkPromotion.SecondZkPromotionConsumes[0]);
                                                    }else if(itemList.length>1){
                                                        let item=itemList[1];
                                                        if(item.Eat_SalePrice==it.Eat_SalePrice){
                                                            it._promotionNumber++;//记录 原活动菜品 已经使用了几次 折扣
                                                            this.addZKItem(item,zkPromotion.SecondZkPromotionConsumes[0]);
                                                        }
                                                    }
                                                }else{
                                                    it._promotionNumber++;//记录 原活动菜品 已经使用了几次 折扣
                                                    this.addZKItem(item,zkPromotion.SecondZkPromotionConsumes[0]);
                                                    //return true;
                                                }
                                            }
                                        }
                                        
                                    }
                                })
                            }
                        }
                    }
                }
            }
        },
        //返回折扣活动菜品
        getZKPromotionItem(zkPromotion){
            //排除 有其它活动的菜品、 加价口味作法 菜品数量必须大于等于1
            let list= (this.parent.orderMenuList||[]).filter(item=>item.Eat_SalePrice==item.Eat_CheckPrice && item.Eat_Number>=1 && !item.is_taste)
            let zkItemList=[];
            if(zkPromotion.XfbmKind==1){//按菜品小类
                if(zkPromotion.SubFoodKinds?.length>0){
                    list.forEach(item=>{
                        if(zkPromotion.SubFoodKinds.some(it=>it.Item_ID== item.SubCategory_ID)){
                            zkItemList.push(item);
                        }
                    })
                }
            }else{/// =2 按菜品指定
                if(zkPromotion.Foods?.length>0){
                    list.forEach(item=>{
                        if(zkPromotion.Foods.some(it=>it.Item_ID== item.EAT_XFBMID)){
                            zkItemList.push(item);
                        }
                    })
                }
            }
            return zkItemList;
        },
        //返回指定菜品 进行折扣
        getSpecifyItem(zkPromotion,specifyFoods,zkSpecifyFoods){
            //排除 有其它活动的菜品、 加价口味作法 、排除当前拥有第二份折扣活动的菜
            let list=[];
            //排除 有其它活动的菜品、 加价口味作法
            let orderMenuList=(this.parent.orderMenuList||[]).filter(item=>
                item.Eat_Number-item._promotionNumber>=1
                && !item.isPromotion2 && item.Eat_SalePrice==item.Eat_CheckPrice 
                && item.Eat_Number>=1 && !item.is_taste
            );
            
            
           
            orderMenuList.forEach(item=>{
                //排除拥有第二份折扣活动的菜、 查询指定要折扣的菜
                if(specifyFoods?.some(food=>food.Item_ID==item.EAT_XFBMID)){
                    list.push(item);
                }
            })
            if(list.length==0){
                orderMenuList.forEach(item=>{
                    //查询 拥有第二份折扣活动的菜 并且在是指定要折扣的菜
                    if(zkSpecifyFoods?.some(food=>food.Item_ID==item.EAT_XFBMID)){
                        list.push(item);
                    }
                })
            }

            if(list.length>0){
                if(zkPromotion.PromKindType==2){//按指定菜品范围打折【优先价低者折扣】 
                    list.sort((d1,d2)=>d1.Eat_SalePrice-d2.Eat_SalePrice);//销售价 顺序
                }else{//按指定菜品范围打折【优先价高者折扣】 
                    list.sort((d1,d2)=>d2.Eat_SalePrice-d1.Eat_SalePrice);//销售价 倒序
                }
            }
            return list;
        },
        //拆分 或 修改 菜品折扣
        addZKItem(item,zkConsume){
            if(item.Eat_Number==1){
                item.isPromotion2=true;
                //ReduceNum 优惠值 7折则为70
                item.zkPromotionPrice=Number((item.Eat_SalePrice* (zkConsume.ReduceNum/100)).toFixed(4));
            }else{//数量多个 进行拆分
                let _tempItem=deepClone(item);//复制原菜
                _tempItem.Eat_ConsumeID=newGuid();
                _tempItem.Eat_Number=1;
                _tempItem.FoodModifys=null;//清空口味作法
                _tempItem.Eat_MasName="";
                _tempItem.Is_Combo=false;//拆除组合菜
                _tempItem.Eat_TcAutoID_Batch=null;
                _tempItem.Eat_TcName=null;
                _tempItem.Eat_TcIndex=null;
                _tempItem.ModiPrice=0;
                _tempItem._promotionNumber=0;
                _tempItem.isPromotion2=true;
                _tempItem.zkPromotionPrice=Number((item.Eat_SalePrice* (zkConsume.ReduceNum/100)).toFixed(4));
                let EatNumber=item.Eat_Number;
                _tempItem.FoodSetmeals?.forEach(it=>{
                    it.Eat_ConsumeID=newGuid();
                    it.FoodModifys=null;//清空口味作法
                    it.Eat_MasName="";
                    it.ModiPrice=0;
                    it.FoodSetmeal_Number = Number((it.FoodSetmeal_Number / EatNumber).toFixed(4));//按比例 分配一份数量;
                })
               
                let is_taste=false;//原数据是否有口味加价做法
                //原数据减少一份
                item.Eat_Number--;
                this.parent.orderMenuList.forEach(or=>{
                    if(or.Eat_ConsumeID==item.Eat_ConsumeID && or.is_taste){
                        is_taste=true;
                        if(or.Price_Kind==0){
                            if(or.Eat_Number>1){
                                or.Eat_Number=Number((or.Eat_Number-1).toFixed(4));//加价口味做法 数量减一;
                            }
                        }
                    }
                })
                
                /**有口味 */
                if(item.FoodModifys){
                    item.FoodModifys.forEach(item=>{
                        if(item.Price_Kind==0){
                            if(item.Mas_Num>1){
                                item.Mas_Num= Number((item.Mas_Num-1).toFixed(4));//加价口味做法 数量减一
                            }
                        }
                    })
                }
                //套餐
                item.FoodSetmeals?.forEach(it=>{
                    let num=1;//套餐明细减少一份
                    if(it.FoodSetmeal_Number>1 && it.FoodSetmeal_Number>= EatNumber){
                        num = it.FoodSetmeal_Number / EatNumber;//按比例减少
                    }
                    it.FoodSetmeal_Number = Number((it.FoodSetmeal_Number-num).toFixed(4));

                    if(it.FoodModifys){//套餐明细 加价口味做法 数量 减少
                        let orNumber=0;
                        this.parent.orderMenuList.forEach(or=>{
                            if(or.Eat_ConsumeID==it.Eat_ConsumeID){
                                is_taste=true;
                                if(or.Price_Kind==0 && or.Eat_Number<=it.FoodSetmeal_Number){
                                    or.Eat_Number = it.FoodSetmeal_Number;
                                    orNumber = or.Eat_Number;
                                }else{
                                    if(or.Price_Kind==0){
                                        or.Eat_Number = Number((or.Eat_Number-num).toFixed(4));
                                        orNumber = or.Eat_Number;
                                    }
                                }
                            }
                        })
                        it.FoodModifys.forEach(item=>{
                            if(item.Price_Kind==0){
                                item.Mas_Num =orNumber;
                            }
                        })
                    }
                })

                let index=this.parent.orderMenuList.findIndex(it=>it.Eat_ConsumeID==item.Eat_ConsumeID);
                if(index>=0){
                    if(is_taste){
                        this.parent.orderMenuList.splice(index,0,_tempItem);
                    }else{
                        this.parent.orderMenuList.splice(index+1,0,_tempItem);
                    }
                    this.updateRadio();
                }
            }
        },
        /**满减 
        */
        fullReduction(){
            let youHuiMoney=0;//优惠金额
            let list=(this.parent.orderMenuList||[]);
            if(list.length>0){
                list.forEach(it=>{
                    //满减 扣除 单价
                    it.fullReducePrice=0;
                    it.isFullReduce=false;
                });
               
                let toDay=new Date();//当前时间
                let time=toDay.Format("hh:mm");//时分
                let weekDay=toDay.getDay();//周
                if(weekDay==0){
                    weekDay=7;
                }
                let promotion=this.fullPomotionList?.find(it=>it.BeginTime<=time && it.EndTime>=time && it.WeekDay?.indexOf(weekDay)>=0);
                if(promotion){//满减活动
                
                    let offPromotion=promotion.MoneyOffPromotion;//活动方案
                    let total=this.getFullTotalMoney(offPromotion);
                    let money=total.saleTotalMoney;
                    
                    // if(offPromotion.CheckMoneyType==1){//折后
                    //     money=total.totalMoney;
                    // }else{
                    //     money=total.saleTotalMoney;
                    // }
                    if(offPromotion.MoneyOffPromotionConsumes?.length>0){
                        if(offPromotion.PromKindType==2 || offPromotion.PromKindType==4){//只有一个
                            let consumes=offPromotion.MoneyOffPromotionConsumes[0];
                            if(consumes.FullMoney>0){//金额每满X元 减   要减的金额=向下取整(金额/每满金额)*减金额
                                youHuiMoney=Number((Math.floor(money/consumes.FullMoney)*consumes.ReduceMoney).toFixed(2));
                                if(youHuiMoney>consumes.MaxReduceMoney){//不能超过 最大优惠减金额
                                    youHuiMoney=consumes.MaxReduceMoney;
                                }
                            }
                        }else{//
                            let consumes=offPromotion.MoneyOffPromotionConsumes.find(it=>it.FullMoney<=money);
                            if(consumes){
                                if(consumes.FullMoney>=0){//按订单金额满x元减 OR 赠
                                    youHuiMoney=consumes.ReduceMoney;
                                }
                            }
                        }  
                    }
                    // if(offPromotion.CheckMoneyType==0 ){//如果折前金额 
                    //     if(youHuiMoney>total.totalMoney){//优惠力度大于 实际 结算金额 则优惠金额等于 结算金额
                    //         youHuiMoney=total.totalMoney;
                    //     }
                    // }
                    

                    if(youHuiMoney>0){
                        if(total.saleTotalMoney>0){
                            let perReduce= youHuiMoney/total.saleTotalMoney;
                            total.fullItemList.forEach(it=>{
                                let money=(it.Eat_SalePrice * it.Eat_Number)*(1-perReduce);
                                //满减 扣除 单价
                                it.fullReducePrice=Number((money/it.Eat_Number).toFixed(4));
                                it.isFullReduce=true;
                            })
                        }
                    }
                }
            }
            return youHuiMoney;
        },
        //按满减规则返回订单菜品
        getFullTotalMoney(offPromotion){
            //排除 有其它活动的菜品、 加价口味作法
            let list= (this.parent.orderMenuList||[]).filter(item=>!item.isPromotion2 && item.Eat_SalePrice==item.Eat_CheckPrice && item.Eat_Number>0 && !item.is_taste)
            let totalMoney=0;//结算金额
            let saleTotalMoney=0;//销售金额
            let fullItemList=[];//需要满减的 明细数据
            //活动类型方式 //=1 按订单金额满x元减 OR 赠///= 2 按订单金额每满X元 减 OR 赠
            if(offPromotion.PromKindType==1 || offPromotion.PromKindType==2){ 
                //菜品有点菜数量
                list.forEach(item=>{
                    fullItemList.push(item);
                });
            }else{///= 3 按订单菜品满x元减 OR 赠///= 4 按订单菜品每满X元 减 OR 赠
                if(offPromotion.XfbmKind==1){//活动按菜品方式 /// =1 按菜品小类/// =2 按菜品指定
                    if(offPromotion.SubFoodKinds?.length>0){
                        list.forEach(item=>{
                            if(offPromotion.SubFoodKinds.some(it=>it.Item_ID== item.SubCategory_ID)){
                                fullItemList.push(item);
                            }
                        })
                    }
                }else{/// =2 按菜品指定
                    if(offPromotion.Foods?.length>0){
                        list.forEach(item=>{
                            if(offPromotion.Foods.some(it=>it.Item_ID== item.EAT_XFBMID)){
                                fullItemList.push(item);
                            }
                        })
                    }
                }
            }

            fullItemList.forEach(it=>{
                if(it.Eat_CheckMoney){//如果有结算金额取结算金额
                    totalMoney = totalMoney+ it.Eat_CheckMoney;
                }else{
                    totalMoney = totalMoney+(it.Eat_CheckPrice * it.Eat_Number)  //结算总价格
                }
                if(it.Eat_SaleMoney){//如果有 金额取 金额
                    saleTotalMoney = saleTotalMoney+ it.Eat_SaleMoney;
                }else{
                    saleTotalMoney = saleTotalMoney+(it.Eat_SalePrice * it.Eat_Number)  //总价格
                }
            })


            return {
                saleTotalMoney:Number(saleTotalMoney.toFixed(2)),//保留两位小数
                totalMoney: Number(totalMoney.toFixed(2)),//保留两位小数
                fullItemList:fullItemList
            } 
        },
        /**异常订单不做处理 */
        clickDoNot(){
            this.$confirm("您确认放弃历史单据结账吗?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText:"取消",
				callback: (name) => {
					if(name=='confirm'){
						this.parent.emptydata();
					}
				},
			});
            
        },
        /**清除掉订单主信息 只保留点菜信息 */
        doNotOrder(){
            this.parent.paymentHistory=[],this.parent.Channellike='';
            this.parent.DeskName='',this.parent.meberInfo=null,this.parent.EatAutoID='',this.parent.Timestamps='',
            this.$route.params.data='',this.parent.IsMultipleCardPay=false,
            this.parent.checkBtnlist='',this.parent.checkUser={}
            this.parent.DefaultChannelID = this.parent.userInfo?.Default_ChannelID
            this.parent.eatCheckIndex='';
            this.parent.isAbnormal=false;
            this.parent.Rpt_Date=false;
            this.parent.merCouponFood=[];
            this.parent.headingInfo={
                DeskName:'',
                DeskAutoID:'',
                RoomAutoID:'',
                DinersNum:1,
                orderChannelName:'',
                OrderChannel_AutoID:'',
                brandNumber:'',
                orderRemark:''
            }
            this.parent.OrderChannelUpdate();
            this.parent.thefirstcanteenID();
            setTimeout(() => {
                this.parent.$refs.foodMenu.searchBoxFocus();
            },100);
        }
    },
}
</script>

<style>

</style>