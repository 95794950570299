<template>
    <div class="reportOperationReport defaultReport" data-v-5baaa6fa>
        <div class="filter-box">
            <div class="filter-bnts ">
                <div class="bnt verticalCenter" :class="{selected:queryType==false}" @click="nowadays()" >
                    <div class="title">
                        <span>今天 <br/>({{userInfo.Rpt_Date}})</span>
                    </div>
                </div>
                <div class="bnt verticalCenter" :class="{selected:queryType==true}" @click="queryType=true"  v-if="ReportDataFromDays>=0">
                    <div class="title">
                        <span>按日期范围</span>
                    </div>
                </div>
                <div class="inline-block"  v-if="ReportDataFromDays>=0">
                    <el-date-picker class="from-date"
                        v-model="businessHours"
                        type="daterange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :default-time="defaultTime"
                        @change="change"
                        :disabled='queryType==false'
                        :disabled-date="disabledDate"
                    > </el-date-picker>
                </div>
                <div class="inline-block">
                    <div class="flex-box">
                        <div class="lable-txt">销售渠道:</div>
                        <div class="from-input" style="width: 180px;">
                            <select-tree v-model="channelAutoIDs" placeholder="请选择" :data="treeOrderChannel" title="销售渠道选择"/>
                        </div>
                    </div>
                </div>
                <div class="inline-block select" v-if="tagType!=8">
                    <el-select v-model="searchType" placeholder="按菜品">
                        <el-option :value="1" label="按菜品"></el-option>
                        <el-option :value="2" label="按台号"></el-option>
                        <el-option :value="3" label="按账单号"></el-option>
                    </el-select>
                </div>
                <div class="inline-block">
                    <div class="search-flex">
                        <div class="search-input">
                            <input type="text" v-model="doSoldText"  placeholder="沽清检索" v-if="tagType==8"/>
                            <input type="text" v-model="selectContent"  placeholder="模糊检索" v-else />
                        </div>
                        <div class="bnt-search" @click="AbnormalCheck()" >查询</div>
                    </div>
                </div>
            </div>
            <div class="right-bnts">
                <div class="bnt" @click="exportExcel()"><i class="iconfont icon-daochu"></i>导出报表</div>
            </div>
        </div>
        <div class="content-box clearfix">
            <div class="nav-box-top">
                <div class="nav-box" v-mouse-scroll >
                    <div class="nav-li" :class="{selected:tagType==1}" @click="tagClick(1)">退菜</div>
                    <div class="nav-li" :class="{selected:tagType==2}" @click="tagClick(2)">赠送</div>
                    <div class="nav-li" :class="{selected:tagType==3}" @click="tagClick(3)">改量</div>
                    <div class="nav-li" :class="{selected:tagType==4}" @click="tagClick(4)">改价</div>
                    <div class="nav-li" :class="{selected:tagType==5}" @click="tagClick(5)">自定义菜品</div>
                    <div class="nav-li" :class="{selected:tagType==6}" @click="tagClick(6)">转菜</div>
                    <div class="nav-li" :class="{selected:tagType==7}" @click="tagClick(7)">操作桌台</div>
                    <div class="nav-li" :class="{selected:tagType==8}" @click="tagClick(8)">沽清操作</div>
                    <div class="nav-li" :class="{selected:tagType==9}" @click="tagClick(9)">账单绑卡</div>
                    <div class="nav-li" :class="{selected:tagType==10}" @click="tagClick(10)">返积分统计报表</div>
                </div>
            </div>
            <!--退菜明细数据-->
            <div class="tab-pane" v-show="tagType==1">
                <div class="table-box grey-table isFooter">
                    <el-table-vir ref="tableEl" id="ReturnFoodTable" border 
                        :data="ReturnFoodLogReportsOrder" style="width: 100%;"  
                        @current-change="handleCurrentChange"
                        :row-class-name="tableRowClass"
                        @sort-change="(column)=>sortChange(column,1)" 
                        :trHeight="50"
                        show-summary
                        :summary-method="getSummariesReturn"
                        v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}">
                        <el-table-column sortable prop="Rpt_Date" label="营业日期" is-render min-width="90" :formatter="shortDateFormat"></el-table-column>
                        <el-table-column sortable prop="CheckNo" label="结账单号" is-render min-width="160" align="left"></el-table-column> 
                        <el-table-column sortable prop="DeskName" label="台号" is-render min-width="60" align="left"></el-table-column> 
                        <el-table-column sortable prop="Eat_XFCode" label="菜品编码" is-render min-width="80" align="left"></el-table-column>
                        <el-table-column sortable prop="Eat_XFName" label="菜品名称" is-render min-width="100" align="left"></el-table-column>
                        <el-table-column sortable prop="Eat_Time" label="点单时间" is-render min-width="130" align="left" :formatter="dateFormat"></el-table-column>
                        <el-table-column sortable prop="RefundTime" label="退菜时间" is-render min-width="130" align="left" :formatter="dateFormat"></el-table-column>
                        <el-table-column sortable prop="Duration" label="时长/分" is-render min-width="80" align="right" :formatter="numberFormat"></el-table-column>
                        <el-table-column sortable prop="Eat_Czy" label="点菜人" is-render min-width="80" align="left"></el-table-column>
                        <el-table-column sortable prop="Eat_Number" label="点单数量" is-render min-width="80" data-format="number" align="right" :formatter="numberFormat"></el-table-column>
                        <el-table-column sortable prop="RefundNumber" label="退菜数量" is-render min-width="80" data-format="number" align="right" :formatter="numberFormat"></el-table-column>
                        <el-table-column sortable prop="SalePrice" label="单价" is-render min-width="60" data-format="number" align="right" :formatter="numberFormat"></el-table-column>
                        <el-table-column sortable prop="SaleMoney" label="小计" is-render min-width="70" data-format="number" align="right" :formatter="numberFormat"></el-table-column>
                        <el-table-column sortable prop="Czy" label="退菜人" is-render min-width="80" align="left"></el-table-column>
                        <el-table-column sortable prop="Reason" label="退菜原因" is-render min-width="100" align="left"></el-table-column>
                        <el-table-column sortable prop="Kit_Czy" label="划菜人" is-render min-width="80" align="left"></el-table-column>
                        <el-table-column sortable prop="KitTime" label="划菜时间" is-render min-width="130" align="left" :formatter="dateFormat"></el-table-column>
                        <el-table-column sortable prop="IsUpRefund" label="是否上退" min-width="100" align="left">
                            <template #header>
                                <el-tooltip  effect="dark" content="划菜后退菜" placement="top">
                                    <div class="tip" style="display: inline-block;">是否上退
                                        <svg class="icon-svg" aria-hidden="true">
                                            <use xlink:href="#icon-infoFilled"></use>
                                        </svg>
                                    </div>
                                </el-tooltip>
                            </template>
                            <template #default="scope">
                                {{scope.row.IsUpRefund?"是":"否"}}
                            </template>
                        </el-table-column>
                    </el-table-vir>
                </div>
            </div>
            <!--赠送明细数据-->
            <div class="tab-pane" v-show="tagType==2">
                <div class="table-box grey-table isFooter">
                    <el-table-vir ref="GiftFoodtableEl" id="GiftTable" border 
                        :data="GiftFoodLogReportsOrder" 
                        @current-change="handleCurrentChange"
                        :row-class-name="tableRowClass"
                        @sort-change="(column)=>sortChange(column,2)" 
                        :trHeight="50"
                         show-summary
                        :summary-method="getSummariesGit"
                        style="width: 100%;"  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" >
                        <el-table-column sortable prop="Rpt_Date" label="营业日期" min-width="90">
                            <template #default="scope">
                                <span>{{ new Date(scope.row.Rpt_Date).Format("yyyy-MM-dd")}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="CheckNo" label="结账单号" min-width="160">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.CheckNo}}</span>
                            </template>
                        </el-table-column> 
                        <el-table-column sortable prop="DeskName" label="台号" min-width="60">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.DeskName}}</span>
                            </template>
                        </el-table-column> 
                        <el-table-column sortable prop="Eat_XFCode" label="菜品编码" min-width="80">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Eat_XFCode}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="Eat_XFName" label="菜品名称" min-width="100">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Eat_XFName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="Eat_Size" label="单位" min-width="60">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Eat_Size}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="Eat_Number" label="赠送数量" min-width="80" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.Eat_Number!=0" >{{scope.row.Eat_Number}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="SalePrice" label="单价" min-width="60" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.SalePrice!=0" >{{scope.row.SalePrice}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="SaleMoney" label="赠送金额" min-width="80" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.SaleMoney!=0" >{{scope.row.SaleMoney}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="Czy" label="赠送人" min-width="80" data-format="number">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Czy}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="GiftTime" label="操作时间" min-width="130">
                            <template #default="scope">
                                <span class="lefts">{{ new Date(scope.row.GiftTime).Format("yyyy-MM-dd hh:mm:ss")}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="Eat_StationName" label="站点" min-width="100">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Eat_StationName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="Reason" label="赠送原因" min-width="100">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Reason}}</span>
                            </template>
                        </el-table-column>
                    </el-table-vir>
                </div>
            </div>
            <!--改量明细数据-->
            <div class="tab-pane" v-show="tagType==3">
                <div class="table-box grey-table">
                    <el-table-vir ref="ChangeNumtableEl" id="ChangeNumTable" border 
                        :data="ChangeNumLogReportsOrder" 
                        @current-change="handleCurrentChange"
                        @sort-change="(column)=>sortChange(column,3)" 
                        :row-class-name="tableRowClass"
                        :trHeight="50"
                        style="width: 100%;"  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}">
                        <el-table-column sortable prop="Rpt_Date" label="营业日期" min-width="90">
                            <template #default="scope">
                                <span>{{ new Date(scope.row.Rpt_Date).Format("yyyy-MM-dd")}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="CheckNo" label="结账单号" min-width="160">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.CheckNo}}</span>
                            </template>
                        </el-table-column> 
                        <el-table-column sortable prop="DeskName" label="台号" min-width="60">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.DeskName}}</span>
                            </template>
                        </el-table-column> 
                        <el-table-column sortable prop="Eat_XFCode" label="菜品编码" min-width="80">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Eat_XFCode}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="Eat_XFName" label="菜品名称" min-width="100">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Eat_XFName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="Eat_Size" label="单位" min-width="60">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Eat_Size}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="SalePrice" label="单价" min-width="60" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.SalePrice!=0" >{{scope.row.SalePrice}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="OldNumber" label="改前数量" min-width="80" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.OldNumber!=0" >{{scope.row.OldNumber}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="NewNumber" label="改后数量" min-width="80" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.NewNumber!=0" >{{scope.row.NewNumber}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="DiffNumber" label="差值" min-width="70" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.DiffNumber!=0" >{{scope.row.DiffNumber}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="DiffSaleMoney" label="差额小计" min-width="80" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.DiffSaleMoney!=0" >{{scope.row.DiffSaleMoney}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="Czy" label="操作人" min-width="70">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Czy}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="OperateTime" label="操作时间" min-width="130">
                            <template #default="scope">
                                <span class="lefts">{{ new Date(scope.row.OperateTime).Format("yyyy-MM-dd hh:mm:ss")}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="Eat_StationName" label="站点" min-width="100">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Eat_StationName}}</span>
                            </template>
                        </el-table-column>
                    </el-table-vir>
                </div>
            </div>
            <!--改价明细数据-->
            <div class="tab-pane" v-show="tagType==4">
                <div class="table-box grey-table">
                    <el-table-vir ref="ChangePricetableEl" id="ChangePriceTable" border 
                        :data="ChangePriceLogReportsOrder"  
                        @sort-change="(column)=>sortChange(column,4)" 
                        :trHeight="50"
                        @current-change="handleCurrentChange"
                        :row-class-name="tableRowClass"
                        style="width: 100%;"  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" :default-sort = "{prop: 'date', order: 'descending'}">
                        <el-table-column sortable prop="Rpt_Date" label="营业日期" min-width="90">
                            <template #default="scope">
                                <span>{{ new Date(scope.row.Rpt_Date).Format("yyyy-MM-dd")}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="CheckNo" label="结账单号" min-width="160">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.CheckNo}}</span>
                            </template>
                        </el-table-column> 
                        <el-table-column sortable prop="DeskName" label="台号" min-width="60">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.DeskName}}</span>
                            </template>
                        </el-table-column> 
                        <el-table-column sortable prop="Eat_XFCode" label="菜品编码" min-width="80">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Eat_XFCode}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="Eat_XFName" label="菜品名称" min-width="100">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Eat_XFName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="Eat_Size" label="单位" min-width="60">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Eat_Size}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="Eat_Number" label="数量" min-width="60" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.Eat_Number!=0" >{{scope.row.Eat_Number}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="OldPrice" label="改前单价" min-width="80" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.OldPrice!=0" >{{scope.row.OldPrice}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="NewPrice" label="改后单价" min-width="80" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.NewPrice!=0" >{{scope.row.NewPrice}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="DiffPrice" label="差值" min-width="70" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.DiffPrice!=0" >{{scope.row.DiffPrice}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="DiffSaleMoney" label="差额小计" min-width="80" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.DiffSaleMoney!=0" >{{scope.row.DiffSaleMoney}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="Czy" label="操作人" min-width="70">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Czy}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="GiftTime" label="操作时间" min-width="130">
                            <template #default="scope">
                                <span class="lefts">{{ new Date(scope.row.OperateTime).Format("yyyy-MM-dd hh:mm:ss")}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="Eat_StationName" label="站点" min-width="100">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Eat_StationName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="Reason" label="改价原因" min-width="100">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Reason}}</span>
                            </template>
                        </el-table-column>
                    </el-table-vir>
                </div>
            </div>
            <!--自定义菜品明细数据-->
            <div class="tab-pane" v-show="tagType==5">
                <div class="table-box grey-table">
                    <el-table-vir ref="DiyFoodtableEl" id="diyTable" border 
                        :data="DiyFoodLogReportsOrder"  
                        @sort-change="(column)=>sortChange(column,5)" 
                        :trHeight="50"
                        @current-change="handleCurrentChange"
                        :row-class-name="tableRowClass"
                    style="width: 100%;"  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" :default-sort = "{prop: 'date', order: 'descending'}">
                        <el-table-column sortable prop="Rpt_Date" label="营业日期" min-width="90">
                            <template #default="scope">
                                <span>{{ new Date(scope.row.Rpt_Date).Format("yyyy-MM-dd")}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="CheckNo" label="结账单号" min-width="160">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.CheckNo}}</span>
                            </template>
                        </el-table-column> 
                        <el-table-column sortable prop="DeskName" label="台号" min-width="60">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.DeskName}}</span>
                            </template>
                        </el-table-column> 
                        <el-table-column sortable prop="Eat_XFCode" label="菜品编码" min-width="80">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Eat_XFCode}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="Eat_XFName" label="菜品名称" min-width="100">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Eat_XFName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="Eat_Size" label="单位" min-width="60">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Eat_Size}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="Eat_Number" label="数量" min-width="60" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.Eat_Number!=0" >{{scope.row.Eat_Number}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="SalePrice" label="单价" min-width="60" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.SalePrice!=0" >{{scope.row.SalePrice}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="SaleMoney" label="小计" min-width="60" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.SaleMoney!=0" >{{scope.row.SaleMoney}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="Czy" label="操作人" min-width="70">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Czy}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="OperateTime" label="操作时间" min-width="130">
                            <template #default="scope">
                                <span class="lefts">{{ new Date(scope.row.OperateTime).Format("yyyy-MM-dd hh:mm:ss")}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="Eat_StationName" label="站点" min-width="100">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Eat_StationName}}</span>
                            </template>
                        </el-table-column>
                    </el-table-vir>
                </div>
            </div>
            <!--转菜明细数据-->
            <div class="tab-pane" v-show="tagType==6">
                <div class="table-box grey-table">
                    <el-table-vir ref="TransfertableEl" id="TransTable" border 
                        :data="TransferFoodLogReportsOrder" 
                        @sort-change="(column)=>sortChange(column,6)" 
                        :trHeight="50"
                        @current-change="handleCurrentChange"
                        :row-class-name="tableRowClass"
                        style="width: 100%;"  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" :default-sort = "{prop: 'date', order: 'descending'}">
                        <el-table-column sortable prop="Rpt_Date" label="营业日期" min-width="90">
                            <template #default="scope">
                                <span>{{ new Date(scope.row.Rpt_Date).Format("yyyy-MM-dd")}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="OldDeskName" label="原桌号" min-width="80">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.OldDeskName}}</span>
                            </template>
                        </el-table-column> 
                        <el-table-column sortable prop="NewDeskName" label="往桌号" min-width="80">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.NewDeskName}}</span>
                            </template>
                        </el-table-column> 
                        <el-table-column sortable prop="Eat_XFCode" label="菜品编码" min-width="70">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Eat_XFCode}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="Eat_XFName" label="菜品名称" min-width="100">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Eat_XFName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="Eat_Number" label="数量" min-width="50" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.Eat_Number!=0" >{{scope.row.Eat_Number}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="Czy" label="操作人" min-width="70">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Czy}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="OperateTime" label="操作时间" min-width="130">
                            <template #default="scope">
                                <span class="lefts">{{ new Date(scope.row.OperateTime).Format("yyyy-MM-dd hh:mm:ss")}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="Eat_StationName" label="站点" min-width="100">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Eat_StationName}}</span>
                            </template>
                        </el-table-column>
                    </el-table-vir>
                </div>
            </div>
            <!--操作桌台明细数据-->
            <div class="tab-pane" v-show="tagType==7">
                <div class="table-box grey-table">
                    <el-table-vir ref="OperationtableEl" ID="OperaTable" border 
                        :data="OperationDeskLogReportsOrder" 
                        @sort-change="(column)=>sortChange(column,7)" 
                        style="width: 100%;"  
                        :trHeight="50"
                        v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}">
                        <el-table-column sortable prop="Rpt_Date" label="营业日期" min-width="90">
                            <template #default="scope">
                                <span>{{ new Date(scope.row.Rpt_Date).Format("yyyy-MM-dd")}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="Kind" label="操作类型" min-width="80">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Kind}}</span>
                            </template>
                        </el-table-column> 
                        <el-table-column sortable prop="OldDeskName" label="原桌号" min-width="80">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.OldDeskName}}</span>
                            </template>
                        </el-table-column> 
                        <el-table-column sortable prop="NewDeskName" label="往桌号" min-width="80">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.NewDeskName}}</span>
                            </template>
                        </el-table-column> 
                        <el-table-column sortable prop="Czy" label="操作人" min-width="70">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Czy}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="OperateTime" label="操作时间" min-width="130">
                            <template #default="scope">
                                <span class="lefts">{{ new Date(scope.row.OperateTime).Format("yyyy-MM-dd hh:mm:ss")}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="Eat_StationName" label="站点" min-width="100">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Eat_StationName}}</span>
                            </template>
                        </el-table-column>
                    </el-table-vir>
                </div>
            </div>
            <!--沽清操作日志-->
            <div class="tab-pane" v-show="tagType==8">
                <div class="table-box grey-table">
                    <table-vir ref="DoSoldtableEl" ID="DoSoldTable" border 
                        :data="doSoldOutLogFilet"
                        @sort-change="(column)=>sortChange(column,8)" 
                        style="width: 100%;"  
                        :trHeight="50">
                        <table-column-vir sortable="custom" prop="Rpt_Date" label="营业日期" min-width="90" :formatter="shortDateFormat"></table-column-vir>
                        <table-column-vir sortable="custom" prop="Kind" label="操作类型" min-width="80">
                            <template #default="scope">
                                <div class="tag" v-if="scope.row.Kind=='沽清'">沽清
                                    <div class="tagAngle"></div>
                                </div>
                                <div class="taglimi" v-if="scope.row.Kind=='限量'">限量
                                    <div class="tagAngle"></div>
                                </div>
                                <span  v-if="scope.row.Kind!='沽清' && scope.row.Kind!='限量'" >{{scope.row.Kind}}</span>
                            </template>
                        </table-column-vir> 
                        <table-column-vir sortable="custom" prop="Eat_XFCode" label="菜品编码" min-width="80" align="left"></table-column-vir> 
                        <table-column-vir sortable="custom" prop="Eat_XFName" label="菜品名称" min-width="80" align="left"></table-column-vir> 
                        <table-column-vir sortable="custom" prop="Eat_Size" label="菜品单位" min-width="80" align="left"></table-column-vir> 
                        <table-column-vir sortable="custom" prop="Old_Limited_Num" label="原可售数量" min-width="90" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.Old_Limited_Num!=0 || scope.row.Kind=='设置限量' " >{{scope.row.Old_Limited_Num}}</span>
                            </template>
                        </table-column-vir> 
                        <table-column-vir sortable="custom" prop="Now_Limited_Num" label="改为可售数量" min-width="100" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.Now_Limited_Num!=0 || scope.row.Kind=='设置限量' " >{{scope.row.Now_Limited_Num}}</span>
                            </template>
                        </table-column-vir> 
                        <table-column-vir sortable="custom" prop="Eat_Phase" label="限售时段" min-width="80" align="left"></table-column-vir>
                        <table-column-vir sortable="custom" prop="Czy" label="操作人" min-width="80"  align="left"></table-column-vir>
                        <table-column-vir sortable="custom" prop="OperateTime" label="操作时间" min-width="130" :formatter="dateFormat"></table-column-vir>
                        <table-column-vir sortable="custom" prop="Eat_StationName" label="站点" min-width="100" align="left"></table-column-vir>
                    </table-vir>
                </div>
            </div>
            <!--账单绑卡-->
            <div class="tab-pane" v-show="tagType==9">
                <div class="table-box grey-table">
                    <el-table-vir ref="orderCardtableEl" border 
                        :data="OrderBindCardLogReportsOrder"
                        @sort-change="(column)=>sortChange(column,9)" 
                        :trHeight="50"
                        style="width: 100%;"  
                        v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" >
                        <el-table-column sortable prop="Rpt_Date" label="营业日期" min-width="90">
                            <template #default="scope">
                                <span>{{ new Date(scope.row.Rpt_Date).Format("yyyy-MM-dd")}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="CheckNo" label="结账单号" min-width="160">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.CheckNo}}</span>
                            </template>
                        </el-table-column> 
                        <el-table-column sortable prop="DeskName" label="台号" min-width="60">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.DeskName}}</span>
                            </template>
                        </el-table-column> 
                        <el-table-column sortable prop="Operete_OldValue" label="原记录" min-width="200">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Operete_OldValue}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="Operate_Vaule" label="现记录" min-width="200">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Operate_Vaule}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="Czy" label="操作人" min-width="80">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Czy}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="Operate_Time" label="操作时间" min-width="130">
                            <template #default="scope">
                                <span class="lefts">{{ new Date(scope.row.Operate_Time).Format("yyyy-MM-dd hh:mm:ss")}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="Operate_Pos" label="操作站点" min-width="90">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Operate_Pos}}</span>
                            </template>
                        </el-table-column>
                    </el-table-vir>
                </div>
            </div>
            <!--返积分统计报表-->
            <div class="tab-pane" v-show="tagType==10">
                <div class="table-box grey-table isFooter">
                    <el-table-vir ref="orderReceivetableEl" border 
                        :data="ReceivePointReportsOrder"  
                        @sort-change="(column)=>sortChange(column,10)" 
                        :trHeight="50"
                        style="width: 100%;"  
                        show-summary
                        :summary-method="getSummariesReceive"
                        v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" >
                        <el-table-column sortable prop="Rpt_Date" label="营业日期" min-width="80" :formatter="shortDateFormat"></el-table-column>
                        <el-table-column sortable prop="DeskName" label="台号" min-width="80"></el-table-column> 
                        <el-table-column sortable prop="CheckNo" label="结账单号" min-width="200"></el-table-column> 
                        <el-table-column sortable prop="Eat_SaleMoney" label="消费总额" min-width="100"  align="right" :formatter="priceFormat"></el-table-column>
                        <el-table-column sortable prop="Eat_ZKMoney" label="折扣额" min-width="100" align="right" :formatter="priceFormat"> </el-table-column>
                        <el-table-column sortable prop="Eat_CheckMoney" label="结账金额" min-width="100" align="right" :formatter="priceFormat"></el-table-column>
                        <el-table-column sortable prop="Eat_FactMoney" label="实收金额" min-width="100" align="right" :formatter="priceFormat"></el-table-column>
                        <el-table-column sortable prop="PayMessage" label="付款情况" min-width="200"></el-table-column>
                        <el-table-column sortable prop="Eat_Phase" label="时段" min-width="80"></el-table-column>
                        <el-table-column sortable prop="Eat_BTime" label="开台时间" min-width="150" :formatter="dateFormat"></el-table-column>
                        <el-table-column sortable prop="Eat_ETime" label="结账时间" min-width="150" :formatter="dateFormat"></el-table-column>
                        <el-table-column sortable prop="Receive_Point" label="返积分" min-width="80" :formatter="numberFormat"></el-table-column>
                        <el-table-column sortable prop="Eat_CardNo" label="卡号" min-width="100"></el-table-column>
                        <el-table-column sortable prop="Operate_Pos" label="收银站点" min-width="100"></el-table-column>
                        <el-table-column sortable prop="Czy" label="结账人" min-width="80"></el-table-column>
                    </el-table-vir>
                </div>
            </div>
        </div>
        <!--权限控制-->
        <modal-load :isShow="checkShow">
            <check-user-button-model :isShow="checkShow" v-on:closeModel="checkShow=false" :checkBtnlist="checkBtnlist" @checkbtnReturn="checkbtnReturn" ></check-user-button-model>
        </modal-load>
     </div>
</template>
<script>
import checkUserButtonModel from '../../../checkUserRightModel/checkUserButtonModel.vue'


/**异常操作统计表 */
export default {
    name:'reportOperationReport',
    components:{
        checkUserButtonModel
    },
    data(){
        return {
            userInfo:[],
            /**默认日期范围 */
            defaultTime:[new Date(),new Date()],
            /**选中日期范围 */
            businessHours:[new Date(),new Date()],
            /**true: 日期范围    false：当前营业数据*/
            queryType:false,
            /**检索类型 1 按菜品  2按台号  3按账单号*/
            searchType:1,
            selectContent:'',
            //沽清检索
            doSoldText:"",
            ReturnFoodLogReports:[],
            GiftFoodLogReports:[],
            ChangeNumLogReports:[],
            TransferFoodLogReports:[],
            ChangePriceLogReports:[],
            DiyFoodLogReports:[],
            OperationDeskLogReports:[],
            DoSoldOutLogReports:[],
            OrderBindCardLogReports:[],
            //返积分统计报表
            ReceivePointReports:[],
            tagType:1,
            arrlenth:[],
            checkShow:false,
            /**权限验证临时保存ID和操作员 */
            tempUserId:'',
            tempOperatorName:'',
            checkBtnlist:{},
            //渠道数据
            orderChannel:null,
            //渠道id
            channelAutoIDs:[],
            /**报表 排序用 多个报表*/
            sortable:[]
        }
    },
    mounted(){
        this.userInfo = this.$auth.getUserInfo();
        this.tempUserId='',this.tempOperatorName='';
        //渠道数据
        this.$cacheData.OrderChannel().then((d)=>{
            if(d){
                this.orderChannel=d;
            }
        }).catch((e)=>{
            this.$message.error('加载渠道数据失败：'+e.message);
            console.log('加载渠道数据失败：'+e);
        })
        this.nowadays();
    },
    computed:{
        //限制门店用户查看几天以内的报表, -1:不能查看历史数据，0:不限制，大于0：限制查看几天内的报表
        ReportDataFromDays(){
            return this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserViewReportDataFromDays||0
        },
        //沽清操作日志 数据 筛选
        doSoldOutLogFilet(){
            let data=Object.assign([],this.DoSoldOutLogReports);
            if(this.doSoldText!=''){
                let MenuTxt=this.doSoldText.toLowerCase();
                data=data.filter(item=>(item.Eat_XFName || "").toLowerCase().indexOf(MenuTxt)>=0 || (item.Eat_XFCode || "").toLowerCase().indexOf(MenuTxt)>=0 || (item.PINYIN || "").toLowerCase().indexOf(MenuTxt)>=0)
            }
            //排序
            if(this.sortable[8]){
                let sorttype=this.sortable[8].order=='ascending'?'asc':'desc';
                data.order(this.sortable[8].prop,sorttype);
            }
            return data;
        },
        //渠道数据
        treeOrderChannel(){
            let treeData=[];
            this.orderChannel?.map((group)=>{
                let json={
                    id:group.OrderChannelKind_AutoID,
                    name:group.OrderChannelKind_Code+group.OrderChannelKind_Name,
                    children:[],
                    Hierarchy:1
                }
                if(group.OrderChannelList){
                    group.OrderChannelList.map(item=>{
                        let children= Object.assign({
                            id:item.OrderChannel_AutoID,
                            name:item.OrderChannel_Code+'-'+item.OrderChannel_Name,
                            Hierarchy:2
                        },item)
                        json.children.push(children)
                    })
                }
                treeData.push(json)
            })
            return treeData;
        },
        //退菜明细数据 排序
        ReturnFoodLogReportsOrder(){
            return this.orderList(this.ReturnFoodLogReports,this.sortable[1]);
        },
        //赠送明细数据 排序
        GiftFoodLogReportsOrder(){
            return this.orderList(this.GiftFoodLogReports,this.sortable[2]);
        },
        //改量明细数据 排序
        ChangeNumLogReportsOrder(){
            return this.orderList(this.ChangeNumLogReports,this.sortable[3]);
        },
        //改价明细数据 排序
        ChangePriceLogReportsOrder(){
            return this.orderList(this.ChangePriceLogReports,this.sortable[4]);
        },
        //自定义菜品明细数据 排序
        DiyFoodLogReportsOrder(){
            return this.orderList(this.DiyFoodLogReports,this.sortable[5]);
        },
        //转菜明细数据 排序
        TransferFoodLogReportsOrder(){
            return this.orderList(this.TransferFoodLogReports,this.sortable[6]);
        },
        //操作桌台明细数据 排序
        OperationDeskLogReportsOrder(){
            return this.orderList(this.OperationDeskLogReports,this.sortable[7]);
        },
        //账单绑卡 排序
        OrderBindCardLogReportsOrder(){
            return this.orderList(this.OrderBindCardLogReports,this.sortable[9]);
        },
        //账单绑卡 排序
        ReceivePointReportsOrder(){
            return this.orderList(this.ReceivePointReports,this.sortable[10]);
        }
    },
    methods:{
        disabledDate(d){
            if(this.ReportDataFromDays>0 && this.userInfo.Rpt_Date){
                const oneDay =  24 * 3600 * 1000;
                const day = this.ReportDataFromDays * oneDay;
                let maxDate=new Date(this.userInfo.Rpt_Date).getTime()-oneDay;
                let minDate=maxDate-day;
                if(minDate>=d.getTime() || maxDate<d.getTime()){
                    return true;
                }
            }
            return false;
        },
        tagClick(type){
            this.tagType = type;
        },
        /*数字格式 */
        numberFormat(row, column, cellValue) {
            if(cellValue!=0){
                return cellValue;
            }
            return '';
        },
        /*单价格式 截取两位小数 */
        priceFormat(row, column, cellValue){
            if(isNaN(cellValue)){
                return ""
            }else{
                return parseFloat(parseFloat(cellValue).toFixed(2));
            }
        },
        /*时间格式 */
        dateFormat(row, column, cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd hh:mm:ss');
        },
        /*时间格式 */
        shortDateFormat(row, column, cellValue){
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd');
        },
        /**付款情况 */
        paymentInfo(list){
            let str=[];
            if(list && list.length>0){                
                list.forEach(item => {
                    str.push(item.Payment_Name+":"+item.Payment_Money);
                });
            }
            return str.join(',');
        },
        /**数据排序 */
        sortChange(column,tabIndex){
            this.sortable[tabIndex]=column;
        },
        /**排序 */
        orderList(tableData,sortable){
            let list=Object.assign([],tableData);
            //排序
            if(sortable){
                let sorttype=sortable.order=='ascending'?'asc':'desc';
                list.order(sortable.prop,sorttype);
            }
            return list;
        },
        /**Excel导出 */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }        
            if(this.tagType==1){
                this.$excelCommon.elTableToExcel({
                    elTable:this.$refs.tableEl,
                    titleName:"退菜明细",
                    list:this.ReturnFoodLogReportsOrder
                })
            }else if(this.tagType==2){
                this.$excelCommon.elTableToExcel({
                    elTable:this.$refs.GiftFoodtableEl,
                    titleName:"赠送明细",
                    list:this.GiftFoodLogReportsOrder
                })
            }else if(this.tagType==3){
                this.$excelCommon.elTableToExcel({
                    elTable:this.$refs.ChangeNumtableEl,
                    titleName:"改量明细",
                    list:this.ChangeNumLogReportsOrder
                })
            }else if(this.tagType==4){
                this.$excelCommon.elTableToExcel({
                    elTable:this.$refs.ChangePricetableEl,
                    titleName:"改价明细",
                    list:this.ChangePriceLogReportsOrder
                })
            }else if(this.tagType==5){
                this.$excelCommon.elTableToExcel({
                    elTable:this.$refs.DiyFoodtableEl,
                    titleName:"自定义菜品明细",
                    list:this.DiyFoodLogReportsOrder
                })
            }else if(this.tagType==6){
                this.$excelCommon.elTableToExcel({
                    elTable:this.$refs.TransfertableEl,
                    titleName:"转菜明细",
                    list:this.TransferFoodLogReportsOrder
                })
            }else if(this.tagType==7){
                this.$excelCommon.elTableToExcel({
                    elTable:this.$refs.OperationtableEl,
                    titleName:"操作桌台明细",
                    list:this.OperationDeskLogReportsOrder
                })
            }else if(this.tagType==8){
                this.$refs.DoSoldtableEl.export("沽清操作日志");
                // this.$excelCommon.elTableToExcel({
                //     elTable:this.$refs.DoSoldtableEl,
                //     titleName:"沽清操作日志",
                //     list:this.DoSoldOutLogReports
                // })
            }else if(this.tagType==9){
                this.$excelCommon.elTableToExcel({
                    elTable:this.$refs.orderCardtableEl,
                    titleName:"账单绑卡",
                    list:this.OrderBindCardLogReportsOrder
                })
            }else if(this.tagType==10){
                this.$excelCommon.elTableToExcel({
                    elTable:this.$refs.orderReceivetableEl,
                    titleName:"返积分统计",
                    list:this.ReceivePointReportsOrder
                })
            }
        },
        /**今日 */
        nowadays(){
            this.queryType=false;
            let Rpt_Date=new Date(this.userInfo.Rpt_Date);
            Rpt_Date.setDate(Rpt_Date.getDate()-1);
            this.businessHours=[Rpt_Date.Format("yyyy-MM-dd"),Rpt_Date.Format("yyyy-MM-dd")];
        },
        change(){
            if(this.queryType==false){
                this.queryType=true
            }
        },
        /**查询 */
        AbnormalCheck(){
            let time = JSON.parse(JSON.stringify(this.businessHours));
            let param={
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                DateRange_YN:this.queryType,//是否日期范围查询  true: 日期范围    false：当前营业数据
                Begin_Date:new Date(time[0]).Format("yyyy-MM-dd"),//开始日期
                End_Date:new Date(time[1]).Format("yyyy-MM-dd"), //结束日期
                SearchKind:this.searchType,//检索类型 1 按菜品  2按台号  3按账单号
                SearchStr:this.selectContent, //
                ChannelIDList:this.channelAutoIDs,//渠道
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            if(this.tempUserId!='' && this.tempOperatorName!=''){
                param.User_ID = this.tempUserId
                param.Operator_Name = this.Operator_Name
            }
            this.$httpAES.post("Bestech.CloudPos.AbnormalOperationReport",param,false).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    console.log(d)
                    //退菜明细数据
                    this.ReturnFoodLogReports = d.ResponseBody?.ReturnFoodLogReports||[];
                    //赠送明细数据
                    this.GiftFoodLogReports = d.ResponseBody?.GiftFoodLogReports||[];
                    //改量明细数据
                    this.ChangeNumLogReports = d.ResponseBody?.ChangeNumLogReports||[];
                    //转菜明细数据
                    this.TransferFoodLogReports = d.ResponseBody?.TransferFoodLogReports||[];
                    //改价明细数据
                    this.ChangePriceLogReports = d.ResponseBody?.ChangePriceLogReports||[];
                    //自定义菜品明细数据
                    this.DiyFoodLogReports = d.ResponseBody?.DiyFoodLogReports||[];
                    //操作桌台明细数据
                    this.OperationDeskLogReports = d.ResponseBody?.OperationDeskLogReports||[];
                    //沽清操作日志
                    this.DoSoldOutLogReports = d.ResponseBody?.DoSoldOutLogReports||[];
                    //账单绑卡
                    this.OrderBindCardLogReports = d.ResponseBody?.OrderBindCardLogReports||[];
                    //返积分统计
                    this.ReceivePointReports = d.ResponseBody?.ReceivePointReports||[];

                    this.ReceivePointReports?.forEach(it=>{
                        //付款情况
                        it.PayMessage=this.paymentInfo(it.Order_PaymentDetailList);
                    })
                    
                }else if(d.ResponseHeader.ResultCode==3003){
                    /**权限不足,去验证保存权限 */
                    this.checkBtnlist = Object.assign(this.checkBtnlist,{ProjectItem_Key:d.ResponseHeader.Msg_Reserve})
                    this.checkShow = true;
                }else{
                    this.Clear()
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                this.Clear()
                loading.close();
                this.$message.error(e?.message);
                console.log(e);
            })
        },
        /**清空数据 */
        Clear(){
            this.ReturnFoodLogReports=[],this.GiftFoodLogReports=[],this.ChangeNumLogReports=[],this.TransferFoodLogReports=[]
            this.ChangePriceLogReports=[],this.DiyFoodLogReports=[],this.OperationDeskLogReports=[],this.DoSoldOutLogReports=[];
            this.ReceivePointReports=[];
            this.OrderBindCardLogReports=[];
        },
        /**权限验证返回 */
        checkbtnReturn(date){
            this.checkShow = false;
            this.tempUserId = date.User_ID
            this.tempOperatorName = date.Login_Name
            this.AbnormalCheck()
        },
        //奇偶行背景色不同
        setCurrent(row) {
            this.$refs.tableEl.setCurrentRow(row);
            this.$refs.GiftFoodtableEl.setCurrentRow(row);
            this.$refs.ChangeNumtableEl.setCurrentRow(row);
            this.$refs.ChangePricetableEl.setCurrentRow(row);
            this.$refs.DiyFoodtableEl.setCurrentRow(row);
            this.$refs.TransfertableEl.setCurrentRow(row);
            this.$refs.OperationtableEl.setCurrentRow(row);
            this.$refs.orderCardtableEl.setCurrentRow(row);
        },
        handleCurrentChange(val) {
            this.currentRow = val;
        },
        tableRowClass({row, rowIndex}) {
            if ((rowIndex + 1) % 2 === 0) {
                return 'success-row';
            }
            return '';
        },
        //合计
        getSummariesReturn({ columns, data }){
            const sums= [];
            let total={Eat_Number:0,RefundNumber:0,SaleMoney:0};
            this.ReturnFoodLogReports?.forEach(it=>{
                total.Eat_Number+=it.Eat_Number;
                total.RefundNumber+=it.RefundNumber;
                total.SaleMoney+=it.SaleMoney;
            })
            columns.forEach((column, index) => {
                if(total[column.property]!=undefined){
                    sums[index]=parseFloat(total[column.property].toFixed(2));
                }else{
                    sums[index]="";
                }
            })
            sums[0]="合计";
            return sums;
        },//合计
        getSummariesGit({ columns, data }){
            const sums= [];
            let total={Eat_Number:0,SaleMoney:0};
            this.GiftFoodLogReports?.forEach(it=>{
                total.Eat_Number+=it.Eat_Number;
                total.SaleMoney+=it.SaleMoney;
            })
            columns.forEach((column, index) => {
                if(total[column.property]!=undefined){
                    sums[index]=parseFloat(total[column.property].toFixed(2));
                }else{
                    sums[index]="";
                }
            })
            sums[0]="合计";
            return sums;
        },
        //返积分合计
        getSummariesReceive({ columns, data }){
            const sums= [];
            let total={Eat_SaleMoney:0,Eat_ZKMoney:0,Eat_CheckMoney:0,Eat_FactMoney:0,Receive_Point:0};
            this.ReceivePointReports?.forEach(it=>{
                total.Eat_SaleMoney+=it.Eat_SaleMoney;
                total.Eat_ZKMoney+=it.Eat_ZKMoney;
                total.Eat_CheckMoney+=it.Eat_CheckMoney;
                total.Eat_FactMoney+=it.Eat_FactMoney;
                total.Receive_Point+=it.Receive_Point;
            })
            total.DeskName=(this.ReceivePointReports||[]).length+"笔";
            columns.forEach((column, index) => {
                if(column.property=="DeskName"){
                    sums[index]=total.DeskName;
                }else if(total[column.property]!=undefined){
                    sums[index]=parseFloat(total[column.property].toFixed(2));
                }else{
                    sums[index]="";
                }
            })
            sums[0]="合计";
            return sums;
        }
    }
}
</script>

<style lang="scss" >
@import './reportOperationReport.scss'
</style>