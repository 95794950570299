<template>
  <modal class="lockScreenModel" :isShow="isShow">
    <div class="modal-body">
        <div class="loginbox">
            <floating-menu cacheName="lockScreen_floatkeyboard"/>
            <div class="suo-logo">
                <span class="iconfont " :class="isLock?'icon-suo':'icon-jiesuo'"></span>
            </div>
            <div class="flex-box">
                <div class="flex-grow-0">
                    <div class="login-title"><img width="40" height="42" src="/images/BSLogo.png" />
                    <span style="position:absolute;">{{Department_Name}}</span>
                    </div>
                    <div class="li">
                        <div class="lable-txt"><span class="iconfont icon-yonghu"></span></div>
                        <input type="text" ref="name" class="key-focus" data-model='userName' @focus="tabIndex=1" v-model="userName" placeholder="请输入用户名" @keyup.enter="KeyClick(1)" />
                    </div>
                    <div class="li">
                        <div class="lable-txt"><span class="iconfont icon-password"></span></div>
                        <input type="password" ref="pasword" class="key-focus" @focus="tabIndex=2" data-model='userPW' v-model="userPW" placeholder="请输入密码" @keyup.enter="KeyClick(2)" />
                    </div>
                    <div class="li flex-box"><button type="button" class="btn bnt-close" @click="close()">退出程序</button><button type="button" class="btn bnt-login" @click="unlock()">解锁</button></div>
                </div>
                <div class="flex-grow-1 keyboard">
                    <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                            data-value="9">9</span></div>
                    <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                            data-value="6">6</span></div>
                    <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                            data-value="3">3</span></div>
                    <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                            data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                    <div class="key-li"><span data-value="clean">清除</span><span class="bnt-confirm" data-value=""
                            @click="next">确认</span></div>
                </div>
            </div>
        </div>
    </div>
  </modal>
</template>

<script>
import {initKeyBoard} from '/src/common/index.js';

export default {
    name:'lockScreenModel',
    data(){
        return{
            Department_Name:'',
            userName:'',
            userPW:'',
            tabIndex:0,
            isLock:true,
            isLogin:false,
            //是否开启读卡登录 功能
            isRedCardLogin:false,
            //是否停止读卡登录
            isStopRedCard:true,
            //输入框监听 卡号
            inputCard:"",
            //第一次输入时间
            inputStartTime:0,
            // 输入间隔 100毫秒
            inoutSpaceTime:500
        }
    },
    props:{
         isShow:Boolean,
    },
    mounted(){
        if(!this.$global.devices || !this.$global.devices.uuid){
            this.$webBrowser.getDevices().then(data=>{
                if(data){
                    this.$global.devices=data;
                }
            })
        }
        this.$nextTick(()=> {
            initKeyBoard.call(this,this.$el);
            //默认焦点
            this.$refs.name.focus();
            let userInfo=this.$auth.getUserInfo();
            if(userInfo){
				this.Department_Name=userInfo.Department_Name;
			}
            this.$global.isLockScreen=this.isShow;

            this.$webBrowser.getBaseSetup().then(d=>{
                if(d?.isRedCardLogin){
                    this.isRedCardLogin=d?.isRedCardLogin;
                    this.startCardRed();//开启读卡
                }
            })
				
        })
    },
    watch:{
        isShow(value){
            if(value==true){
                this.$global.isLockScreen=true;
                this.userName='';
                this.userPW='';
                this.isLock=true;
                //默认焦点
                this.$refs?.name?.focus();
            }
        },
        userName(newVal,oldVal){
            if(!oldVal || (oldVal && (newVal||"").indexOf(oldVal)==0)){
                let time=(new Date()).getTime();
                if(this.inputStartTime+this.inoutSpaceTime<time){
                    this.inputStartTime=time;
                    this.inputCard="";
                }
                let oldLeng=oldVal?.length||0;
                this.inputCard+=newVal.substring(oldLeng);
            }
        }
    },
    methods:{
        /**input回车 */
        KeyClick(type){
            let time=(new Date()).getTime();
            if(type==1 && this.inputCard?.length>6 && this.inputStartTime+this.inoutSpaceTime>time){//输入名称回撤
                this.unlock({
                    Login_Type:3,//刷卡登录
                    Card_ID:this.inputCard
                })
                return;
            }

            if(type==1){
                this.$refs.pasword.focus();
            }else{
                this.unlock();
            }
        },
        next(){
            if(this.tabIndex==1){
                this.$refs.pasword.focus();
            }
            else if(this.tabIndex==2){
                this.unlock();
            }
        },
        //开启读卡
        startCardRed(){
            if(this.isRedCardLogin && this.isStopRedCard){
                this.isStopRedCard=false;
                setTimeout(()=>{
                    this.redCardLoain();
                },2000);//间隔2秒 读卡
            }
        },
        redCardLoain(){
            if(this.isStopRedCard || this.isLogin || this.layer){
                return;
            }
            this.$webBrowser.redCardM1().then((d)=>{
                if(d && !this.isStopRedCard){
                    if(d.state==0){
                        if(!d.data.snr){
                            this.$message.warning({ message: "卡号为空,请换张卡!"});
                            return;
                        }
                        this.isStopRedCard=true;
                        this.unlock({
                            Login_Type:3,//刷卡登录
                            Card_ID:d.data.snr
                        })
                        return;
                    }
                    setTimeout(()=>{
                        this.redCardLoain();
                    },2000);//间隔2秒 读卡
                }
            })
        },
        unlock(paramData){
            let param={
                Sys_ID:801,
            }
            if(paramData){
                Object.assign(param,paramData);
            }else{
                Object.assign(param,{
                    Login_Type:0,
                    Login_Name:this.userName,
                    Login_Pwd:this.userPW,
                });
                if (this.userName == "" || this.userName == null) {
                    this.$message.warning({ message: "用户名不能为空"});
                    return;
                }
            }
            
            if(this.isLogin) return;
            this.isLogin=true;
            const loading = this.$loading({
                text: "请求加载中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.isLock=false;
            let userInfo=this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.LoginPos", param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.isLogin=false;
                    this.isLock=true;
                    if(d.ResponseHeader.ResultCode==2002 || d.ResponseHeader.ResultCode==2004){//未注册
                        this.$message.error({ message:d.ResponseHeader.ResultDesc});
                        this.$router.push('/register');
                        return;
                    }
                    this.$message.error(d.ResponseHeader.ResultDesc);
                    this.startCardRed();
                    return;
                }
                this.$global.isLockScreen=false;
                //系统项目列表
                this.$cacheData.SysProjects.set(d.ResponseBody.SysProjects);
                if(userInfo.id!=d.ResponseBody.User_ID){
                    let _busType=[];
                    d.ResponseBody?.Business_Types.map(bus=>{
                        let _name=''
                        if(bus==0){
                            _name='中餐'
                        }
                        if(bus==1){
                            _name='快餐'
                        }
                        if(bus==2){
                            _name='零售'
                        }
                        if(bus==3){
                            _name='美食广场'
                        }
                        let item={value:bus,lable:_name}
                        _busType.push(item)
                    })
                    this.$auth.login({
                        id:d.ResponseBody.User_ID,
						User_ID:d.ResponseBody.User_ID, //登录用户ID
                        User_Code:d.ResponseBody.User_Code,//用户编号
						Rpt_Date:d.ResponseBody.Rpt_Date,//营业日期
						Department_Code:d.ResponseBody.Department_Code,//机构编号
						Department_Name:d.ResponseBody.Department_Name,//机构名称
						Login_Name:d.ResponseBody.Login_Name,//用户名称
						Site_Serial:d.ResponseBody.Site_Serial, //设备序列号
						Site_Name:d.ResponseBody.Site_Name, //设备序名称
						Sys_Version:d.ResponseBody?.Sys_Version, //系统版本号
						Default_ChannelID:d.ResponseBody?.Default_ChannelID, //默认渠道
                        Is_PrintServer:d.ResponseBody.Is_PrintServer,//当前登录设备是否打印服务站点，true:是，false:不是
                        BusinessAudits:d.ResponseBody?.BusinessAudits, //营业稽核信息
                        Business_Types:_busType, //站点业态 0：中餐，1：快餐，2：零售，3：美食广场。
                        TheLimitedGiftingMoney:d.ResponseBody?.TheLimitedGiftingMoney, //登录用户的最大赠送金额
                        IsAllowOperateMoreDepartments:d.ResponseBody?.IsAllowOperateMoreDepartments,//是否多门店
                        OrgDepartments:d.ResponseBody?.OrgDepartments,//可选门店
                    });
                    //系统项目列表
                    this.$cacheData.SysProjects.set(d.ResponseBody.SysProjects);
                    //特殊功能权限列表
                    this.$cacheData.FuncRights.set(d.ResponseBody.FuncRights);
                    const baseLoading = this.$loading({
                        text: "基础数据加载中",
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.7)",
                    });
                    let dataNum=0;
                    let hideLoaing=()=>{
                        dataNum++;
                        if(dataNum>=2){
                            this.isLogin=false;
                            try {
                                baseLoading.close();
                                this.$message.success('解锁成功');
                                this.$router.push('/home/diningTable');
                                this.$emit("routerLoad");
                            } catch (error) {
                                console.log(error)
                            }
                            
                        }
                    }; 
                    this.$cacheData.cacheBaseAll(["GlobalSysSetting","OrderChannel","ConsumeTypes","SalesPromotions"]).then((e)=>{
                        hideLoaing();
                        console.log(e)
                    }).catch((e)=>{
                        hideLoaing();
                        console.log('基础数据加载失败:'+e);
                    })
                    this.$cacheData.PosFoods().then(()=>{
                        hideLoaing();
                    }).catch(e=>{
                        hideLoaing();
                        console.log('未找到菜品数据:'+e)
                    });
                }else{
                    this.isLogin=false;
                    this.$emit("closeModel");
                    this.$emit("routerLoad");
                }
            }).catch((e)=>{
                this.isLogin=false;
                loading.close();
                this.isLock=true;
                this.startCardRed();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        close() {
            
            this.$confirm("是否确认关闭系统", "提示", {
				confirmButtonText: "确定",
				cancelButtonText:"取消",
				callback: (name) => {
					if(name=='confirm'){
						const loading = this.$loading({
                            text: "退出登录中...",
                            spinner: "el-icon-loading",
                            background: "rgba(0, 0, 0, 0.7)",
                        });
                        let userInfo=this.$auth.getUserInfo();
                        let param={
                            User_ID:userInfo.User_ID, //登陆用户ID
                            User_Name:userInfo.Login_Name
                        }
                        this.$httpAES.post("Bestech.CloudPos.LogoutPos",param).then((data)=>{
                            loading.close();
                            this.$webBrowser.close(false);
                        }).catch((e)=>{
                            loading.close();
                            this.$message.error('退出登录失败：'+e);
                            console.log('退出登录失败：'+e);
                        })
					}
				},
			});
		},
    },
    //被卸载时发生
    unmounted(){
        this.isStopRedCard=true;//停止读卡
    }
}
</script>

<style lang="scss">
   @import "./lockScreenModel.scss"
</style>