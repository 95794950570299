<template>
    <teleport to="body" v-if="isToBody">
        <div class="floatingMenu defaultStyle" :style="style" ref="floatingMenu" @mousedown="(e)=>e.preventDefault()" @click="click()">
            <i class="iconfont icon-jianpan"></i>
        </div>
    </teleport>
    <div v-else class="floatingMenu" :style="style" ref="floatingMenu" @mousedown="(e)=>e.preventDefault()" @click="click()">
        <i class="iconfont icon-jianpan"></i>
    </div>
</template>

<script>
/**悬浮 菜单按钮 */
export default {
    name:"floatingMenu",
    props:{
        /**缓存用 key */
        cacheName:{
            type:String,
            default:null
        },
        /**是否 to=body */
        isToBody:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return {
            style:null,
            isMove:false
        }
    },
    watch:{
        style(newVal){
            if(this.cacheName){
                this.$cacheData.set(this.cacheName,newVal||"")
            }
        }
    },
    mounted(){
        if(this.cacheName){
            this.style=this.$cacheData.get(this.cacheName)||null;
            if(this.style){//有默认位置
                this.$nextTick(()=>{
                    let rect=this.$refs.floatingMenu?.getBoundingClientRect();
                    if(rect){
                        let y=rect.top;
                        let x=rect.left;
                        let padding=5;
                        if(x<padding){
                            x=padding;
                        }else if(x>window.innerWidth-55-padding){
                            x=window.innerWidth-55-padding;
                        }
                        if(y<padding){
                            y=padding;
                        }else if(y>window.innerHeight-38-padding){
                            y=window.innerHeight-38-padding;
                        }
                        this.style={
                            position:"fixed",
                            left:x+"px",
                            top:y+"px",
                        }
                    }
                })
            }
        }
        
        let startLeft,startTop;
        let top=0,left=0;
        let mousedown=(e)=>{
            this.isMove=false;
            let rect=e.currentTarget.getBoundingClientRect();
            top=rect.top;
            left=rect.left;
            if(!this.isToBody){
                this.style={
                    position:"fixed",
                    left:left+"px",
                    top:top+"px",
                }
            }
            if(e.type=="mousedown"){
                startLeft=e.x;
                startTop=e.y;
                document.addEventListener("mousemove",mousemove);
                document.addEventListener("mouseup",mouseup);
            }else{
                if(e.targetTouches?.length>0){
                    document.addEventListener("touchmove",mousemove);
                    document.addEventListener("touchend",mouseup);
                    startLeft=e.targetTouches[0].pageX;
                    startTop=e.targetTouches[0].pageY;
                }
            }
        }
        let mousemove=(e)=>{
            let x=top,y=left;
            if(e.type=="mousemove"){
                x=left+e.x-startLeft;
                y=top+e.y-startTop;
            }else{
                if(e.targetTouches?.length>0){
                    x=left+e.targetTouches[0].pageX-startLeft;
                    y=top+e.targetTouches[0].pageY-startTop;
                }
            }
            let padding=5;
            if(x<padding){
                x=padding;
            }else if(x>window.innerWidth-55-padding){
                x=window.innerWidth-55-padding;
            }
            if(y<padding){
                y=padding;
            }else if(y>window.innerHeight-38-padding){
                y=window.innerHeight-38-padding;
            }
            this.style={
                position:"fixed",
                left:x+"px",
                top:y+"px",
            }
            if(top!=x || left!=top){
                this.isMove=true;
            }
        }
        let mouseup=(e)=>{
            if(e.type=="mouseup"){
                document.removeEventListener("mousemove",mousemove);
                document.removeEventListener("mouseup",mouseup);
            }else{
                document.removeEventListener("touchmove",mousemove);
                document.removeEventListener("touchend",mouseup);
            }
        }
        this.$refs.floatingMenu.addEventListener("mousedown",mousedown)
        this.$refs.floatingMenu.addEventListener("touchstart",mousedown)
    },
    methods:{
        click(){
            if(!this.isMove){//在移动就不触发点击了
                console.log("click")
                //弹出系统键盘
                this.$webBrowser.openWindKeyBoard();
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.floatingMenu{
    width: 55px;
    height: 38px;
    border-radius:5px;
    line-height: 40px;
    text-align: center;
    background-color: rgba(255,255,255,0.8);
    box-shadow: 0 0 8px 2px rgba(0,0,0,0.5);
    z-index: 9;

    &.defaultStyle{
        position: fixed;
        left:10px;
        bottom: 10px;
    }

    .iconfont{
        font-size: 32px;
        color: #b1b1b1;
    }

    &.selected{
        box-shadow: 0 0 8px 2px #487BB1;

        .iconfont{
            color: #487BB1;
        }
    }
}
</style>